<template>
  <div>
    <div class="input-title">휴대폰번호<span class="color-1">*</span></div>

    <template v-if="isPropSns">
      <input-field class="w-100p" v-model="phone" numberOnly placeholder="휴대폰번호" maxLength="11" disabled readonly/>
    </template>
    <template v-else>
      <div v-if="isRequest || isModify" class="input-field flex-between-center mb-5">
        <input-field class="w-66p" v-model="phone" numberOnly placeholder="휴대폰번호" maxLength="11" disabled readonly/>
        <div class="w-32p"><button class="btn btn-x-large bgcolor-1 dark btn-block" @click="modify()">수정</button></div>
      </div>
      <div v-else class="input-field flex-between-center mb-5">
        <input-field class="w-66p" v-model="phone" numberOnly placeholder="휴대폰번호" maxLength="11" @focusout="chkPhone"/>
        <div class="w-32p"><button class="btn btn-x-large bgcolor-1 dark btn-block" @click="request()">인증번호 받기</button></div>
      </div>

      <div class="input-field input-number-check d-block mb-5">
        <input-field v-model="number" numberOnly placeholder="인증번호 입력" maxLength="6" :disabled="!isRequest" :readonly="isChecked"/>
        <timer v-if="isRequest && !isChecked" @expire="numberExpired" :style="timerColor" />
        <span v-if="isChecked" class="stateText color-success">일치</span>
      </div>
    </template>



    <div v-if="message" :class="'error-message'">{{ message }}</div>
  </div>
</template>

<script>
import {mapActions} from "vuex"

export default {
  name: "phoneVerify",
  props: ['propPhone', 'isPropModify', 'isPropSns'],
  data() {
    return {
      phonePattern: /^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})-?[0-9]{3,4}-?[0-9]{4}$/,
      phone: '',
      number: '',
      timerColor: null,
      isRequest: false,
      isExpired: false,
      isChecked: false,
      isModify: this.isPropModify,
      message: null,
    }
  },
  watch: {
    number() {
      if (this.number.length == 6) this.checkVerify();
    },
    propPhone(v) {
      this.phone = v
    }
  },
  methods: {
    ...mapActions({
      setMsgPopup: 'common/setMsgPopup',
    }),
    async chkPhone() {
      if (!this.phone) {
        this.message = this.$msg('required.input')
        return false
      }
      if (!this.phone.patternCheck('phone')) {
        this.message = this.$msg('phone.check')
        return false
      }
      const { result } = await this.$api.chkPhone({ phone: this.phone })
      switch (result) {
        case 'success':
          this.message = ''
          return true
          break
        case 'fail':
        case 'phone-no-input':
        case 'phone-check':
          this.message = this.$msg('phone.check')
          return false
          break
        case 'phone-duple':
          this.message = this.$msg('phone.exist')
          return false
          break
        default:
      }
    },
    async request() {
      if (await this.chkPhone()) {
        const { result } = await this.$api.sendVerifiPhone({ phone: this.phone })
        if (result === 'success') {
          this.alertPopup()
          this.isRequest = true
          this.isExpired = false
          this.message = null
        }
      }
    },
    modify() {
      this.phone = ''
      this.number = ''
      this.timerColor = null
      this.isRequest = false
      this.isExpired = false
      this.isChecked = false
      this.isModify = false
    },
    numberExpired() {
      this.isExpired = true
      this.message = this.$msg('authNumber.check')
    },
    async checkVerify() {
      if (this.chkVerify()) {
        const { result } = await this.$api.checkVerifiPhone({
          phone: this.phone,
          number: this.number
        })
        if (result === 'success') {
          this.timerColor = null
          this.isChecked = true
          this.message = null
        }
        else {
          this.message = this.$msg('authNumber.check')
          this.timerColor = 'color: #ff693a'
        }
      }
    },
    chkVerify() {
      if (!this.phone || !this.number) {
        this.message = this.$msg('required.input')
        return false
      }
      if (this.number.length != 6) {
        this.message = this.$msg('authNumber.check')
        return false
      }
      if (this.isExpired) {
        this.message = this.$msg('authNumber.check')
        return false
      }
      this.message = null
      return true
    },
    validate() {
      if (this.chkVerify()) {
        if (!this.isChecked) {
          this.message = this.$msg('authNumber.check')
          return false
        }
        return true
      }
      return false
    },
    alertPopup() {
      this.setMsgPopup({
        type: 'alert',
        message: this.$msg('phone.send.number'),
        okay: 'OK',
        okayCallback: () => {
          this.setMsgPopup()
        }
      })
    },
  }
}
</script>
